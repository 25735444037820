<template>
  <div class="about">
    <h1>This is an about page</h1>
    <div ref="chart" style="width:100%;height: 500px;">222</div>
  </div>
</template>


<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
    
  },
  
  mounted() {
    this.initCharts();
  },
  
  methods: {
    initCharts() {
      const chart = this.$refs.chart
      if (chart) {
        this.chart = this.$echarts.init(chart);
        this.setOptions();
      }
    },
    
    setOptions() {
      this.chart.setOption({
        title: {
          text: 'ECharts 入门示例'
        },
        tooltip: {},
        xAxis: {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            smooth: true
        }]
      })
    }
  },
}
</script>